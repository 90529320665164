@use 'app/shared/styles/carousel';

#menu-admin ul {
  top: 0;
  left: 70px;
}

body.body-nao-autenticado {
  overflow: auto !important;
}

img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  image-rendering: high-quality;
}

.msg-error {
  color: red;
}

.interna.conteudo h4.chamada {
  font-weight: bold;
  font-size: 28px !important;
  color: #222222;
}

.text-center {
  text-align: center;
}

.nao-autenticado .container-fluid, .body-nao-autenticado .container-fluid {
  max-width: 1640px;
  width: 100%;
}

.corpo-nao-autenticado {
  padding: 75px 0;
  margin: 0;
}

.titulo-pagina {
  text-align: left;
  background: linear-gradient(-35deg, #00446A 50%, #FE6A00 50.2%);

  & h1 {
    font-weight: bold;
    font-size: 38px !important;
    color: #fff;
    padding: 20px 140px 20px 15px;
    width: fit-content;
    margin: 0;
  }
}

.caption-tabela {
  padding-top: .2rem;
  padding-bottom: .5rem;
  color: #fc4400;
  caption-side: bottom;
  padding-left: 40.5%;
  font-size: 0.8em;
  font-weight: bold;
}

.pd-0 {
  padding: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.disabled {
  cursor: not-allowed !important;
  opacity: .65;
}

.btn {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 2;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
  outline: 0;

  &:focus {
    outline: 0 !important;
  }

  &:focus, &:hover {
    text-decoration: none;
    box-shadow: 0 1px 2px 2px #ddd;
  }

  &.active, &:active {
    background-image: none;
    outline: 0;
  }

  &.disabled, &:disabled {
    cursor: not-allowed;
    opacity: .65;
  }

  &.btn-primary {
    background-color: #ff5a00;
    border-color: #d85108;
    box-shadow: none;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    border-radius: 4px !important;

    &:hover {
      box-shadow: 0 1px 2px 2px #fd7d00;
    }

    &.btn-primary.disabled, &.btn-primary:disabled {
      background-color: #ff5a00 !important;
      border-color: #d85108 !important;
      box-shadow: none !important;
    }

    &.disabled {
      box-shadow: none !important;
    }

    &.disabled:hover {
      box-shadow: none !important;
      background-color: #ff5a00 !important;
      border-color: #d85108 !important;
    }

    &:hover, &:focus, &:visited {
      background-color: #fd7d00 !important;
      border-color: #d85108 !important;
    }
  }

  &.btn-secondary {
    background-color: #ffffff;
    box-shadow: none;
    color: #373a3c !important;
    font-weight: bold;
    text-decoration: none !important;
    border-radius: 4px !important;

    &.disabled, &:disabled {
      background-color: #ffffff;
      box-shadow: none !important;
    }

    &:hover, &:focus {
      background-color: #eeeeee;
      color: #373a3c !important;
      border-color: #cccccc;
      box-shadow: none;
      text-decoration: none !important;
    }
  }

  &.btn-principal {
    background-color: #57a000;
    box-shadow: none;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    border-radius: 4px !important;

    &:hover, &:focus {
      background-color: #65ba00;
      box-shadow: none;
    }
  }

  &.btn-danger {
    background: #c92b1f !important;
    box-shadow: none;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none !important;
    border-radius: 4px !important;

    &:hover, :focus {
      background: #ec2516 !important;
      box-shadow: none;
    }
  }

  &.btn-laranja {
    color: #fff !important;
    background: #FE6A00;
    border: 2px solid #FE6A00;
    border-radius: 4px !important;
    font-weight: bold;
    text-decoration: none !important;

    &:hover, &:focus {
      color: #fff !important;
      background: #EC6300;
      border: 2px solid #EC6300;
    }
  }

  &.btn-laranja-borda {
    color: #FE6A00 !important;
    background: #fff;
    border: 2px solid #FE6A00;
    border-radius: 4px !important;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #fff !important;
      background: #FE6A00;
      border: 2px solid #FE6A00;
    }
  }

  &.btn-azul {
    color: #fff !important;
    background: #007CC1 !important;
    border: 2px solid #007CC1 !important;
    border-radius: 4px !important;
    text-decoration: none !important;

    &:hover, &:focus {
      color: #fff !important;
      background: #1074AC !important;
      border: 2px solid #1074AC !important;
      text-decoration: none !important;
    }
  }

  &.btn-white {
    background-color: #fff;
  }

  &.btn-azul-borda {
    color: #0DA9FF !important;
    border: 2px solid #0DA9FF;
    text-decoration: none !important;
    font-weight: bold;

    &:hover, &:focus {
      background: #007CC1;
      border: 2px solid #007CC1;
      color: #fff !important;
    }

    &.texto-destaque {
      margin-left: 15px;
    }

    &.inicio .compra-viajabox {
      margin-top: 15px;
    }
  }
}

a {
  color: #5f5f5f;
  text-decoration: underline;
  outline: none;
  background-color: transparent;

  &:hover, &:focus {
    color: #ff5a00 !important;
    text-decoration: underline;
  }

  &:focus {
    outline: dotted thin;
    outline-offset: -2px
  }

  &:active, &:hover {
    outline: 0;
  }
}

.alert {
  padding: 10px 15px;
  font-size: 0.9rem;
}

.center {
  margin: 0 auto !important;
}

.conteudo:not(.nao-autenticado) {
  width: 100%;
}

.interna {
  &.conteudo {
    padding: 100px 30px 70px 96px;
    margin-left: 0 !important;
    background: #ffffff;
  }

  &.conteudo:not(.inicial) a:not(.btn), &.conteudo:not(.ajuda) a:not(.btn) {
    color: #0275d8;
    text-decoration: none;
  }

  &.conteudo:not(.inicial) a:not(.btn):hover {
    text-decoration: underline;
  }

  &.conteudo h1 {
    color: #eb5807;
    margin: 0 0 30px 0;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    font-size: 1.5rem;
  }

  &.conteudo h2 {
    color: #666666;
    font-size: 1.1rem !important;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

video {
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 320px;
  height: 240px;
  margin-top: 6px;
}

.mb-0 {
  margin-bottom: 0;
}

.pb-20 {
  padding-bottom: 20px;
}

.navbar-brand > img, .navbar-brand > a > img {
  float: left;
  cursor: pointer;
}

.navbar-brand:hover {
  cursor: default;
}

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
  max-height: fit-content;
}

.topo-index a, .topo-interna a {
  text-decoration: none !important;
}

.enviolabel {
  color: #b5460a;
}

.destaque {
  color: red;
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
  margin-left: inherit !important;
}

body .body:not(.body-nao-autenticado) #inputEmail {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  color: #55595c;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.ng-valid.ng-dirty:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(84, 161, 1, 0.6) !important;
}

.ng-valid.ng-dirty {
  border-color: #54a101 !important;
  box-shadow: none !important;
}

.interna.conteudo .card-header {
  background: #ddd;
  border-bottom: 1px solid #ccc;
}

.interna.conteudo .card {
  border: 1px solid #ccc;
}

.interna.conteudo .row-centered {
  text-align: center;
}

.interna.conteudo .col-centered {
  display: inline-block;
  float: none;
  text-align: left;
  margin-right: -4px;
}

.autocomplete-usuario {
  display: table-cell;
}

.autocomplete-usuario ul.lista-opcoes-input li a {
  overflow-wrap: break-word;
  line-height: 1.2em;
}

ul.lista-opcoes-input {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  list-style-type: none;
  padding-left: 0;
  max-height: 220px;
  overflow-y: auto;
  margin: 0;
}

ul.lista-opcoes-input li a {
  background: #fff;
  cursor: pointer;
  display: block;
  padding: 10px !important;
  text-decoration: none !important;
  width: 100%;
  word-wrap: break-word;
}

ul.lista-opcoes-input li:nth-of-type(2n+1) a {
  background-color: #eee;
}

ul.lista-opcoes-input li:hover a, ul.lista-opcoes-input li:focus a {
  background: #ffe5d8;
  color: #5f5f5f !important;
}

.lista-opcao-selecionada-input {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style-type: none;
  padding: 8px;
  display: block;
  text-decoration: none !important;
  margin: 0;
  line-height: 1.2em;
}

.lista-opcao-selecionada-input span {
  word-wrap: break-word;
}

.lista-opcao-selecionada-input a {
  padding: 7px !important;
  cursor: pointer;
}

option {
  min-height: 30px;
  padding: 5px 10px !important;
}

/******* TABLES *******/
.header {
  border: none !important;
}

table {

  background-color: transparent;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;

  .tohide {
    display: table-cell;
  }

  .sscreen, .xscreen, .xinline {
    display: none;
  }

  & td, & th {
    border-top: 1px solid #eceeef
  }

  & th {
    background: #ddd;
    text-align: center;
  }

  & td {
    border-left: none;
    border-right: none;
    text-align: center;
    vertical-align: middle !important;
  }

  tbody + tbody {
    border-top: 2px solid #eceeef
  }

  & thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef
  }
}

.opcoes {
  & > a {
    cursor: pointer;
    font-size: 1.3rem;
    margin-right: 5px;
    padding: 0 !important;
    text-decoration: none !important;

    & > .ng-fa-icon {
      opacity: .8;
    }

    & > i:hover, & > .ng-fa-icon:hover {
      opacity: 1;
    }
  }

  .ng-fa-icon {
    padding: 5px;

    .fa-edit {
      padding: 5px 0 5px 5px;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #e8e8e8
}

.table-responsive {
  border: none !important;
}

.modal.modal-cupom .title {
  color: #555555 !important;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 33px;
}

.clear {
  clear: both;
}

.clear-md, .clear-sm, .clear-xs {
  display: none;
}

/******* ESTOQUE *******/
.interna.conteudo:not(.inicial) .card.card-estoque {
  border: 0;
  background: none;
}

.interna.conteudo:not(.inicial) .card.card-estoque .card-block {
  background: #fff;
  border: 1px solid #ddd;
  border-top: none;
  display: table;
  width: 100%;
}

.card.card-estoque .nav-tabs li {
  margin-right: 3px;
}

.card.card-estoque .card-default {
  background: #fff;
  color: #777;
  border: 1px solid #ccc;
}

.card.card-estoque .card-primary {
  background: #fff;
  color: #191919;
  border: 1px solid #ccc;
  border-bottom: 1px solid #fff !important;
  font-weight: bold;
}

.img-produto-tabela {
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  height: 100px;
  max-width: 150px;
  object-fit: cover;
}

.fa-circle-check {
  color: green;
}

.botoes-acao-form {
  padding: 10px 0;
  text-align: right;

  & > a, & > button {
    margin-bottom: 6px;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.btn-plus {
  display: inline-block;
  margin-left: 5px;

  a {
    padding: 5px;
  }

  .ng-fa-icon {
    font-size: 1.5rem;
    color: #ff5a00;
    vertical-align: sub;
    cursor: pointer;
  }

  &:hover {
    .ng-fa-icon {
      color: #fd7d00;
    }
  }
}

.form-endereco-selecionado {
  border-radius: 5px !important;
  line-height: 1.5;
  margin: 10px 0 15px;
  padding: 10px 15px;
  border: 1px solid #d8d8d8;
  background: #eee;
}

table.tabela-extras {
  width: 100%;
}

.tabela-extras td {
  padding: 5px 10px !important;
  text-align: left !important;
}

.tabela-extras .checkbox {
  display: table-cell !important;
  margin: 0 !important;
  position: inherit !important;
  width: 20px !important;
}

.tabela-extras .checkbox input {
  position: inherit !important;
}

.tabela-extras label {
  display: table-cell;
  margin-bottom: 0;
}

.tabela-extras tr > td:last-child {
  min-width: 100px !important;
  text-align: right !important;
}

.tabela-extras .extra-gratis {
  font-weight: bold;
  color: green;
}

.tabela-extras .extra-pago {
  font-weight: bold;
  color: #ff5a00;
}

// Calendário

.cal-hour-segment {
  cursor: pointer;
}

.cal-month-view {

  & .cal-header {
    background-color: #ddd !important;
    cursor: default;

    & .cal-weekend {
      cursor: default !important;
    }
  }

  & .cal-day-badge {
    position: absolute;
    display: none;
  }

  & .cal-events {
    position: absolute;
    bottom: 0;
  }

  & .cal-day-cell, & .cal-cell-top {
    min-height: inherit;
    position: relative;
  }

  & .cal-day-cell {
    cursor: pointer;

    &.cal-weekend .cal-day-number {
      color: #c27271 !important;
      opacity: 0.3 !important;
    }
  }

  & .cal-day-selected {
    background-color: #ddd !important;

    & .cal-day-number {
      color: #ff5a00 !important;
      font-weight: bold !important;
    }

    &:hover {
      background-color: #ccc !important;
    }
  }

  & .cal-weekend {
    cursor: not-allowed !important;
  }

  & .cal-days {
    border-color: #fed2ba;

    & .cal-cell-row {
      border-bottom-color: #fed2ba;
    }

    &.cal-today {
      background-color: #e8f7d7 !important;
      color: #54a101;
      opacity: 1;

      & .cal-cell-top {
        border: 1px solid #54a101 !important;
        font-weight: bold;
      }

      &:hover {
        background-color: #d6f4b5 !important;
      }
    }
  }

  & .cal-day-cell.cal-today {
    &:hover {
      background-color: #d6f4b5;
      color: #54a101;
    }

    & .cal-day-number {
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 10px;
      margin-right: 10px;
      color: #54a101;

      &:hover {
        color: #54a101;
      }
    }
  }

  & .cal-day-number {
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.2em;
    text-align: right;
    opacity: 1;
    margin-top: 11px;
    margin-right: 11px;
    user-select: none;

    &:hover {
      color: #ff5a00;
    }
  }
}

.no-events {
  pointer-events: none;
}

.cal-disabled {
  background-color: #ffe4d5;
  cursor: not-allowed !important;
  pointer-events: none;

  &:hover {
    opacity: 0.5 !important;
  }
}

.cal-disabled .cal-day-number {
  color: #c27271 !important;
  opacity: 0.3 !important;
}

.header1-calendario {
  background: #fed2ba;
  width: 100%;
  padding: 5px;

  & .btn.btn-sm {
    padding: 0;
    font-size: 14px;
    line-height: 1;
    height: 30px;
    width: 30px;
  }

  & button {
    &.left {
      display: inline-block;
      float: left;
    }

    &.right {
      display: inline-block;
      float: right !important;
    }
  }

  & .titulo {
    color: #c54905 !important;
    display: inline-block;
    margin-bottom: 0 !important;
    padding: 5px 0;
    font-size: 1.1rem !important;
    font-weight: 700;
  }
}

.header2-calendario {
  background: #eeeeee;
  border: 1px solid #ddd;
  width: 100%;
  float: left;

  & button {
    cursor: pointer;
  }

  & > div {
    padding: 0;
  }

  & .fa-stack-2x {
    font-size: 1.5em;
    top: 4px;
  }

  & .fa-stack-1x {
    font-size: 0.6em;
    line-height: inherit;
    margin-top: 5px;
    color: #fff;
    user-select: none;
  }
}

.view {
  background: none;
  border: none;
  box-shadow: none;
  color: #444;
  float: left;
  padding: 8px 10px;
  margin: 0;
  font-weight: normal;
  cursor: pointer;

  &.active {
    font-weight: bold;
  }

  &:hover {
    color: #ff5a00;
  }
}

.slider-handle {
  top: -1px;
  background-color: #ff5a00 !important;
  background-image: linear-gradient(to bottom, #ff5a00 0, #e97423 100%);
}

.modal-lg-6 {
  width: 45%;
}

.modal .slider {
  width: 40% !important;
}

.btn-loading {
  padding-left: 42px;
  position: relative;
}

.slider.slider-horizontal {
  width: 30%;

  .slider-selection, .slider-track-low, .slider-track-high {
    background: #ff5a00 !important;
    opacity: 0.3;
  }

  .slider-selection {
    opacity: 0.5;
  }

}

.tabela-gerenciar-itens th.header:nth-of-type(2), .tabela-gerenciar-itens th.header:last-child {
  width: 150px;
}

.tabela-gerenciar-itens-vencidos th.header:nth-of-type(2), .tabela-gerenciar-itens-vencidos th.header:last-child {
  width: 150px;
}

.tabela-email-marketing th.header:nth-of-type(2), .tabela-email-marketing th.header:last-child {
  width: 150px;
}

.tabela-gerenciar-itens-caixa th.header:nth-of-type(2), .tabela-gerenciar-itens th.header:last-child {
  width: 150px;
}

.tabela-transfere-itens th.header:nth-of-type(1), .tabela-transfere-itens th.header:last-child {
  width: 150px;
}

.tabela-transfere-itens-historico th.header:nth-of-type(1), .tabela-transfere-itens th.header:last-child {
  width: 150px;
}

.tabela-confere-saida-conferencia th.header:nth-of-type(2), .tabela-confere-saida-conferencia th.header:last-child {
  width: 150px;
}

.tabela-confere-saida-confere-nomes th.header:nth-of-type(2), .tabela-confere-saida-confere-nomes th.header:last-child {
  width: 150px;
}

.tabela-confere-saida-confere-extras th.header:nth-of-type(2), .tabela-confere-saida-confere-extras th.header:last-child {
  width: 150px;
}

.tabela-confere-saida-confere-custo th.header:nth-of-type(2), .tabela-confere-saida-confere-custo th.header:last-child {
  width: 150px;
}

.webcamError {
  display: table-cell;
  line-height: 1.8;
  padding: 20px 40px !important;
  position: absolute;
  text-align: center;
  top: 120px;
  width: 320px;
}

/******* ACCORDION *******/

#accordion .panel {
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(43, 59, 93, 0.30);
  margin-bottom: 10px;
  text-align: left;
}

#accordion .panel-heading {
  width: 100%;
  display: table;
  background-color: #fff;
  padding: 5px 15px;
}

#accordion .panel-default > .panel-heading {
  background-color: #fff;
  position: relative;
}

#accordion .dados-cabecalho-accordion {
  display: table-cell;
  width: calc(100% - 35px);
  float: left;
  height: 100%;
}

#accordion {
  .panel-title {
    font-size: 1rem !important;
    font-weight: bold;
    margin-bottom: 0;
    color: #191919;
    line-height: 2;

    & > span:after {
      content: " – ";
    }

    & > span:last-child:after {
      content: " ";
    }
  }
}

#accordion dl {
  margin-bottom: 0;
}

#accordion dt {
  float: left;
  text-align: right;
  margin-right: 4px;
}

#accordion dt:after {
  content: ":";
}

.opcoes-accordion {
  position: absolute;
  right: 10px;
  text-align: right;

  & a {
    cursor: pointer;
    font-size: 1.3rem;
    margin-left: 5px;
    padding: 0 !important;
    text-decoration: none !important;
  }

  & .ng-fa-icon {
    padding-left: 5px;
  }
}

.opcoes-tabela {
  text-align: center;

  & a {
    cursor: pointer;
    font-size: 1.3rem;
    margin-left: 5px;
    padding: 0 !important;
    text-decoration: none !important;
  }

  & .ng-fa-icon {
    padding-left: 5px;
  }
}

/******* PAGINACAO *******/

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: 1rem;
  border-radius: .25rem;
  margin-bottom: 0;

  & > li > a, & > li > span {
    border: 1px solid #d29a7c !important;
    text-decoration: none !important;
    font-weight: bold;
  }

  & a, & .ng-fa-icon {
    line-height: 1.4;
    height: 36px;
  }

  & > li > a {
    &:hover:not(.active) {
      cursor: pointer;
      text-decoration: none !important;
    }

    &.disabled {
      cursor: not-allowed !important;
      opacity: .5;

      &:hover {
        background-color: inherit;
        color: #0275d8;
      }
    }
  }
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #ff7800 !important;
  border-color: #ff7800 !important;
  color: #fff !important;
  cursor: not-allowed !important;
}

/******* MODAL *******/

.titulo-modal {
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 700;
  color: #ff5a00 !important;
}

.close-modal {
  position: absolute;
  right: 20px;
}

.modal-body {
  display: table !important;
  width: 100%;
  position: relative;
  padding: 15px 25px;
}

#modalEsqueceuSenha label {
  margin-top: 6px !important;
}

.modal .col-btn-foto-anterior, .modal .col-btn-foto-proximo {
  padding-top: 2px;
  width: 80px;
}

.modal .col-foto {
  width: 100%;
}

.acoes-modal-itens {
  height: 45px;

  & > div {
    display: inline-block;
  }
}

.topo-interna .navbar-nav .open .dropdown-menu > li > a {
  line-height: 60px;
  padding-top: 0;
  padding-bottom: 0
}

.interna.conteudo:not(.inicial) .opcoes-accordion a, .interna.conteudo:not(.inicial) .opcoes-accordion a:hover {
  text-decoration: none !important;
}

.fa-angle-down, .fa-angle-up {
  color: #333;
}

.fa-edit {
  color: #00446A;

  &:hover {
    color: #0275d8;
  }
}

.fa-eye {
  color: #777;

  &:hover {
    color: #333;
  }
}

.fa-trash {
  color: #333;

  &:hover {
    color: #000;
  }
}

.fa-barcode {
  color: #000;

  &:hover {
    color: #333;
  }
}

.fa-plane {
  transform: rotate(-50deg);
}

.cropArea {
  background: #E4E4E4;
  overflow: hidden;
  height: 350px;
  text-align: center;
}

.ng-invalid.ng-dirty[required] {
  border-color: #FA787E;
}

input.ng-invalid.ng-dirty {
  border-color: #ff1821;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.simpleCheck {
  width: 28px;
  height: 28px;
  margin: 0 !important;
}

.light-blue-bg {
  background: #025aa5;
  color: #fff;
}

.image-col {
  width: 110px;
}

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.gray-bg {
  background-color: #f3f3f4;
}

.fa-ul, .nested-menu .nested {
  list-style-type: none
}

.fa.fa-pull-left, .fa.pull-left {
  margin-right: .3em
}

.fa-fw, .fa-li {
  text-align: center
}

pre, textarea {
  overflow: auto
}

*, :after, :before, input[type=search] {
  box-sizing: inherit
}

.c-select, .progress[value], input[type=search] {
  -webkit-appearance: none
}

.fa, .fa-stack {
  display: inline-block;
}

.btn, .c-indicator {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}

fieldset, legend, td, th {
  padding: 0
}

img, legend {
  border: 0
}

html {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px !important;
  box-sizing: border-box;
}

[hidden], template {
  display: none;
}

b, optgroup, strong {
  font-weight: 700;
}

dd, h1, h2, h3, h4, h5, h6, label {
  margin-bottom: .5rem;
}

dl, ol, p, pre, ul {
  margin-top: 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

address, legend {
  line-height: inherit
}

button {
  overflow: visible
}

button, select {
  text-transform: none
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], html input[disabled] {
  cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

textarea {
  resize: vertical
}

@media print {
  blockquote, img, pre, tr {
    page-break-inside: avoid
  }
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important
  }
  a, a:visited {
    text-decoration: underline
  }
  abbr[title]:after {
    content: " (" attr(title) ")"
  }
  blockquote, pre {
    border: 1px solid #999
  }
  thead {
    display: table-header-group
  }
  img {
    max-width: 100% !important
  }
  h2, h3 {
    page-break-after: avoid
  }
  .navbar {
    display: none
  }
  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important
  }
  .label {
    border: 1px solid #000
  }
  .table {
    border-collapse: collapse !important
  }
  .table td, .table th {
    background-color: #fff !important
  }
  .td, .th {
    border: 1px solid #ddd !important
  }
}

label, output {
  display: inline-block
}

body {
  height: 100%;
  margin: 0;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #373a3c;
  background-color: #fff
}

[tabindex="-1"]:focus {
  outline: 0 !important
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0
}

abbr[data-original-title], abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #818a91
}

address {
  font-style: normal
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-left: 0
}

blockquote, figure {
  margin: 0 0 1rem
}

[role=button] {
  cursor: pointer
}

[role=button], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

button:focus {
  outline: dotted 1px;
}

button, input, select, textarea {
  margin: 0;
  line-height: inherit;
  border-radius: 0
}

fieldset, .form-group {
  min-width: 0;
  margin: 0;
  border: 0
}

legend {
  display: block;
  width: 100%;
  margin-bottom: .5rem;
  font-size: 1.5rem
}

[hidden] {
  display: none !important
}

h1 {
  font-size: 2.5rem;
  margin: .67em 0;
}

h2 {
  font-size: 1.75rem;
  color: #ff5a00;
  margin-bottom: 0.8rem;

  &.subtitulo {
    font-weight: bold;
    font-size: 28px;
    color: #FE6A00;
    margin-bottom: 30px;

    &:after {
      content: "";
      background: #FE6A00;
      width: 84px;
      height: 2px;
      display: block;
      margin-top: 15px;
    }
  }
}

h3 {
  font-size: 1.5rem;

  &.chamada {
    font-weight: normal;
    font-size: 28px;
    color: #606060;
  }
}

h4 {
  font-size: 1.25rem;

  &.chamada {
    font-weight: 600;
    font-size: 16px;
    color: #202020;
  }
}

h5 {
  font-size: 1rem;
}

hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1)
}

.small, small {
  font-size: 80%;
  font-weight: 400
}

.mark, mark {
  padding: .2em;
  background-color: #f0ad4e
}

.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: 5px
}

.dl-horizontal {
  margin-right: -1.875rem;
  margin-left: -1.875rem
}

.dl-horizontal:after {
  content: "";
  display: table;
  clear: both
}

.initialism {
  font-size: 90%;
  text-transform: uppercase
}

.blockquote {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-left: .25rem solid #eceeef
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  line-height: 1.5;
  color: #818a91
}

.blockquote-footer:before {
  content: "\2014 \00A0"
}

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: .25rem solid #eceeef;
  border-left: 0
}

.blockquote-reverse .blockquote-footer:before {
  content: ""
}

.blockquote-reverse .blockquote-footer:after {
  content: "\00A0 \2014"
}

.figure, .img-thumbnail {
  display: inline-block
}

.img-rounded {
  border-radius: .3rem
}

.img-thumbnail {
  padding: .25rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem;
  transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto
}

.img-circle {
  border-radius: 50%
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.table, pre {
  margin-bottom: 1rem
}

pre {
  display: block;
  font-size: 90%;
  line-height: 1.5;
  color: #373a3c
}

.container-fluid:after, .container:after, .row:after {
  display: table;
  clear: both;
  content: ""
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0
}

.container, .container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: .9375rem;
  padding-right: .9375rem
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: .9375rem;
  padding-right: .9375rem
}

.col-xs-1 {
  width: 8.33333%
}

.col-xs-2 {
  width: 16.66667%
}

.col-xs-3 {
  width: 25%
}

.col-xs-4 {
  width: 33.33333%
}

.col-xs-5 {
  width: 41.66667%
}

.col-xs-6 {
  width: 50%
}

.col-xs-7 {
  width: 58.33333%
}

.col-xs-8 {
  width: 66.66667%
}

.col-xs-9 {
  width: 75%
}

.col-xs-10 {
  width: 83.33333%
}

.col-xs-11 {
  width: 91.66667%
}

.col-xs-12 {
  width: 100%
}

.collapsing, .dropdown-divider, .modal, .modal-open, .navbar-divider {
  overflow: hidden
}

.thead-inverse th {
  color: #fff;
  background-color: #373a3c
}

.thead-default th {
  color: #55595c;
  background-color: #eceeef
}

.form-control, .form-control-file, .form-control-range {
  display: block
}

.form-control {
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  height: 36px;
  line-height: 1.5;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &::placeholder {
    color: #999;
    opacity: 1
  }

  &:disabled, .form-control[readonly] {
    background-color: #eceeef;
    opacity: 1;
    cursor: not-allowed
  }

  &:focus {
    border-color: #ed712e !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 0, 0.6);
  }
}

.form-group {
  margin-bottom: 1rem;
  display: flow-root;

  &.row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

p-inputnumber, .p-inputnumber, .p-inputtext {
  display: block !important;
}

.group-item {
  position: relative;

  & input {
    height: 40px;
    padding-left: 52px;
  }

  & label, div {
    position: absolute;
    width: 40px;
    left: 1px;
    top: 1px;
    font-size: 1rem;
    color: #55595c;
    text-align: center;
    background-color: #eceeef;
    border-right: 1px solid #ccc;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    line-height: 2.4;
  }

  & .ng-fa-icon {
    position: relative;
  }

  &:focus {
    border-color: #ed712e !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(255, 90, 0, 0.6);
  }
}

.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8
}

.btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b
}

.btn-primary.active, .btn-primary:active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
  background-image: none
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .btn-primary.dropdown-toggle.focus, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle:hover {
  color: #fff;
  background-color: #014682;
  border-color: #01315a
}

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary:disabled.focus, .btn-primary:disabled:focus, .btn-primary:disabled:hover {
  background-color: #0275d8;
  border-color: #0275d8
}

.btn-secondary {
  color: #373a3c;
  background-color: #fff;
  border-color: #ccc
}

.btn-secondary.focus, .btn-secondary:focus, .btn-secondary:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad
}

.btn-secondary.active, .btn-secondary:active, .open > .btn-secondary.dropdown-toggle {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
  background-image: none
}

.btn-secondary.active.focus, .btn-secondary.active:focus, .btn-secondary.active:hover, .btn-secondary:active.focus, .btn-secondary:active:focus, .btn-secondary:active:hover, .open > .btn-secondary.dropdown-toggle.focus, .open > .btn-secondary.dropdown-toggle:focus, .open > .btn-secondary.dropdown-toggle:hover {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c
}

.btn-secondary.disabled.focus, .btn-secondary.disabled:focus, .btn-secondary.disabled:hover, .btn-secondary:disabled.focus, .btn-secondary:disabled:focus, .btn-secondary:disabled:hover {
  background-color: #fff;
  border-color: #ccc
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info.focus, .btn-info:focus, .btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2
}

.btn-info.active, .btn-info:active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
  background-image: none
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .btn-info.dropdown-toggle.focus, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a
}

.btn-info.disabled.focus, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info:disabled.focus, .btn-info:disabled:focus, .btn-info:disabled:hover {
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success.focus, .btn-success:focus, .btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641
}

.btn-success.active, .btn-success:active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
  background-image: none
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .btn-success.dropdown-toggle.focus, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle:hover {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d
}

.btn-success.disabled.focus, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success:disabled.focus, .btn-success:disabled:focus, .btn-success:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning.focus, .btn-warning:focus, .btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316
}

.btn-warning.active, .btn-warning:active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
  background-image: none
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .btn-warning.dropdown-toggle.focus, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f
}

.btn-warning.disabled.focus, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning:disabled.focus, .btn-warning:disabled:focus, .btn-warning:disabled:hover {
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger.focus, .btn-danger:focus, .btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a
}

.btn-danger.active, .btn-danger:active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
  background-image: none
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .btn-danger.dropdown-toggle.focus, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e
}

.btn-danger.disabled.focus, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger:disabled.focus, .btn-danger:disabled:focus, .btn-danger:disabled:hover {
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-primary-outline {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8
}

.btn-primary-outline.active, .btn-primary-outline.focus, .btn-primary-outline:active, .btn-primary-outline:focus, .btn-primary-outline:hover, .open > .btn-primary-outline.dropdown-toggle {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8
}

.btn-primary-outline.disabled.focus, .btn-primary-outline.disabled:focus, .btn-primary-outline.disabled:hover, .btn-primary-outline:disabled.focus, .btn-primary-outline:disabled:focus, .btn-primary-outline:disabled:hover {
  border-color: #43a7fd
}

.btn-secondary-outline {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc
}

.btn-secondary-outline.active, .btn-secondary-outline.focus, .btn-secondary-outline:active, .btn-secondary-outline:focus, .btn-secondary-outline:hover, .open > .btn-secondary-outline.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc
}

.btn-secondary-outline.disabled.focus, .btn-secondary-outline.disabled:focus, .btn-secondary-outline.disabled:hover, .btn-secondary-outline:disabled.focus, .btn-secondary-outline:disabled:focus, .btn-secondary-outline:disabled:hover {
  border-color: #fff
}

.btn-info-outline {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de
}

.btn-info-outline.active, .btn-info-outline.focus, .btn-info-outline:active, .btn-info-outline:focus, .btn-info-outline:hover, .open > .btn-info-outline.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info-outline.disabled.focus, .btn-info-outline.disabled:focus, .btn-info-outline.disabled:hover, .btn-info-outline:disabled.focus, .btn-info-outline:disabled:focus, .btn-info-outline:disabled:hover {
  border-color: #b0e1ef
}

.btn-success-outline {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c
}

.btn-success-outline.active, .btn-success-outline.focus, .btn-success-outline:active, .btn-success-outline:focus, .btn-success-outline:hover, .open > .btn-success-outline.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success-outline.disabled.focus, .btn-success-outline.disabled:focus, .btn-success-outline.disabled:hover, .btn-success-outline:disabled.focus, .btn-success-outline:disabled:focus, .btn-success-outline:disabled:hover {
  border-color: #a3d7a3
}

.btn-warning-outline {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e
}

.btn-warning-outline.active, .btn-warning-outline.focus, .btn-warning-outline:active, .btn-warning-outline:focus, .btn-warning-outline:hover, .open > .btn-warning-outline.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning-outline.disabled.focus, .btn-warning-outline.disabled:focus, .btn-warning-outline.disabled:hover, .btn-warning-outline:disabled.focus, .btn-warning-outline:disabled:focus, .btn-warning-outline:disabled:hover {
  border-color: #f8d9ac
}

.btn-danger-outline {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f
}

.btn-danger-outline.active, .btn-danger-outline.focus, .btn-danger-outline:active, .btn-danger-outline:focus, .btn-danger-outline:hover, .open > .btn-danger-outline.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger-outline.disabled.focus, .btn-danger-outline.disabled:focus, .btn-danger-outline.disabled:hover, .btn-danger-outline:disabled.focus, .btn-danger-outline:disabled:focus, .btn-danger-outline:disabled:hover {
  border-color: #eba5a3
}

.btn-link {
  font-weight: 400;
  color: #0275d8;
  border-radius: 0
}

.btn-link, .btn-link.active, .btn-link:active, .btn-link:disabled {
  background-color: transparent
}

.btn-link, .btn-link:active, .btn-link:focus, .btn-link:hover {
  border-color: transparent
}

.btn-link:focus, .btn-link:hover {
  color: #014c8c;
  text-decoration: underline;
  background-color: transparent
}

.btn-link:disabled:focus, .btn-link:disabled:hover {
  color: #818a91;
  text-decoration: none
}

.btn-group-lg > .btn, .btn-lg {
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.33333;
  border-radius: .3rem
}

.btn-group-sm > .btn, .btn-sm {
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem
}

.btn-block {
  display: block
}

.btn-block + .btn-block {
  margin-top: 5px
}

.fade {
  opacity: 0;
  transition: opacity .15s linear
}

.fade.in {
  opacity: 1
}

.collapse {
  display: none;
}

td.collapse {
  display: none !important;
}

td.in {
  display: table-cell !important;
}

.collapsing {
  height: 0;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height
}

.collapsing, .dropdown, .dropup {
  position: relative
}

.dropdown-toggle:focus {
  outline: 0
}

.dropup .dropdown-toggle:after {
  border-top: 0;
  border-bottom: .3em solid
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem
}

.dropdown-header, .dropdown-item {
  display: block;
  padding: 3px 20px;
  line-height: 1.5;
  white-space: nowrap
}

.dropdown-divider {
  height: 1px;
  margin: .5rem 0;
  background-color: #e5e5e5
}

.dropdown-item {
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #373a3c;
  text-align: inherit;
  background: 0 0;
  border: 0
}

.c-indicator, .label, .pager {
  text-align: center
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn, .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #2b2d2f;
  text-decoration: none;
  background-color: #f5f5f5
}

.dropdown-item.active, .dropdown-item.active:focus, .dropdown-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
  outline: 0
}

.dropdown-item.disabled, .dropdown-item.disabled:focus, .dropdown-item.disabled:hover {
  color: #818a91
}

.dropdown-item.disabled:focus, .dropdown-item.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)"
}

.c-input, .file {
  cursor: pointer
}

.open > .dropdown-menu {
  display: block
}

.open > a {
  outline: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-header {
  font-size: .875rem;
  color: #818a91
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: .3em solid
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  float: left
}

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 2
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px
}

.btn-toolbar {
  margin-left: -5px
}

.btn-toolbar:after {
  content: "";
  display: table;
  clear: both
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px
}

.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px
}

.btn-group-lg.btn-group > .btn + .dropdown-toggle, .btn-group > .btn-lg + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%
}

.btn-group-vertical > .btn-group:after {
  content: "";
  display: table;
  clear: both
}

.btn-group-vertical > .btn-group > .btn {
  float: none
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn, .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

[data-toggle=buttons] > .btn input[type=checkbox], [data-toggle=buttons] > .btn input[type=radio], [data-toggle=buttons] > .btn-group > .btn input[type=checkbox], [data-toggle=buttons] > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.c-input, .input-group, .input-group-btn, .input-group-btn > .btn {
  position: relative
}

.input-group {
  display: table;
  border-collapse: separate
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

.input-group .form-control:active, .input-group .form-control:focus, .input-group .form-control:hover, .input-group-btn > .btn:active, .input-group-btn > .btn:focus, .input-group-btn > .btn:hover {
  z-index: 3
}

.input-group .form-control, .input-group-addon, .input-group-btn {
  display: table-cell
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle
}

.input-group-addon {
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #55595c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid #ccc;
  border-radius: .25rem
}

.alert-link, .close, .label {
  font-weight: 700
}

.input-group-addon.form-control-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: .275rem .75rem;
  font-size: .875rem;
  border-radius: .2rem
}

.input-group-addon.form-control-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: .3rem
}

.input-group-addon input[type=checkbox], .input-group-addon input[type=radio] {
  margin-top: 0
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn-group:not(:last-child) > .btn, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.input-group-addon:first-child {
  border-right: 0
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.input-group-addon:last-child {
  border-left: 0
}

.input-group-btn {
  font-size: 0;
  white-space: nowrap
}

.input-group-btn > .btn + .btn {
  margin-left: -1px
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
}

.input-group-btn:last-child > .btn-group:active, .input-group-btn:last-child > .btn-group:focus, .input-group-btn:last-child > .btn-group:hover, .input-group-btn:last-child > .btn:active, .input-group-btn:last-child > .btn:focus, .input-group-btn:last-child > .btn:hover {
  z-index: 3
}

.file {
  position: relative;
  height: 2.5rem
}

.file-custom, .file-custom:before {
  position: absolute;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555
}

.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0
}

.file-custom {
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: .25rem
}

.file-custom:after {
  content: "Choose file..."
}

.file-custom:before {
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 0 .25rem .25rem 0
}

.navbar {
  position: relative;
  min-height: 59px;
  border: none !important;
}

.card, .card-title {
  margin-bottom: .625rem
}

.navbar-brand {
  margin-right: 1rem;
  margin-left: 0 !important;
  font-size: 1.25rem;
  padding: 0 !important;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none
}

.navbar-brand > img {
  display: block
}

.navbar-divider {
  float: left;
  width: 1px;
  padding-top: .425rem;
  padding-bottom: .425rem;
  margin-right: 1rem
}

.navbar-divider:before {
  content: "\00a0"
}

.navbar-toggler {
  padding: .5rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: 0 0;
  border: 1px solid transparent;
  border-radius: .25rem
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none
}

.navbar-nav .nav-link {
  display: block;
  padding-top: .425rem;
  padding-bottom: .425rem
}

.card {
  position: relative;
  display: block;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: .25rem
}

.card-block {
  padding: .875rem
}

.card-footer, .card-header {
  padding: .625rem .875rem;
  background-color: #f5f5f5
}

.card-subtitle {
  margin-top: -.3125rem
}

.card-subtitle, .card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link + .card-link {
  margin-left: .875rem
}

.card > .list-group:first-child .list-group-item:first-child {
  border-radius: .25rem .25rem 0 0
}

.card > .list-group:last-child .list-group-item:last-child {
  border-radius: 0 0 .25rem .25rem
}

.card-header {
  border-bottom: 1px solid #e5e5e5
}

.card-header:first-child {
  border-radius: .25rem .25rem 0 0
}

.card-footer {
  border-top: 1px solid #e5e5e5
}

.card-footer:last-child {
  border-radius: 0 0 .25rem .25rem
}

.card-primary {
  background-color: #0275d8;
  border-color: #0275d8;
  color: white;
}

.card-success {
  background-color: #5cb85c;
  border-color: #5cb85c
}

.card-info {
  background-color: #5bc0de;
  border-color: #5bc0de
}

.card-warning {
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.card-danger {
  background-color: #d9534f;
  border-color: #d9534f
}

.card-danger-outline, .card-info-outline, .card-primary-outline, .card-secondary-outline, .card-success-outline, .card-warning-outline {
  background-color: transparent
}

.card-primary-outline {
  border-color: #0275d8
}

.card-secondary-outline {
  border-color: #ccc
}

.card-info-outline {
  border-color: #5bc0de
}

.card-success-outline {
  border-color: #5cb85c
}

.card-warning-outline {
  border-color: #f0ad4e
}

.card-danger-outline {
  border-color: #d9534f
}

.card-inverse .card-footer, .card-inverse .card-header {
  border-bottom: 1px solid hsla(0, 0%, 100%, .2)
}

.card-inverse .card-blockquote > footer, .card-inverse .card-link, .card-inverse .card-text {
  color: hsla(0, 0%, 100%, .65)
}

.card-inverse .card-link:focus, .card-inverse .card-link:hover {
  color: #fff
}

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0
}

.card-img {
  border-radius: .25rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

.card-img-top {
  border-radius: .25rem .25rem 0 0
}

.card-img-bottom {
  border-radius: 0 0 .25rem .25rem
}

.page-item {
  display: inline
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem
}

.page-item.active .page-link, .page-item.active .page-link:focus, .page-item.active .page-link:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #0275d8;
  border-color: #0275d8
}

.page-item.disabled .page-link, .page-item.disabled .page-link:focus, .page-item.disabled .page-link:hover {
  color: #818a91;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd
}

.page-link {
  position: relative;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #0275d8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd
}

.close, .pager-next > a, .pager-next > span {
  float: right
}

.page-link:focus, .page-link:hover {
  color: #014c8c;
  background-color: #eceeef;
  border-color: #ddd
}

.pager {
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem
}

.pager:after {
  content: "";
  display: table;
  clear: both
}

.pager li {
  display: inline
}

.pager li > a, .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px
}

.pager li > a:focus, .pager li > a:hover {
  text-decoration: none;
  background-color: #eceeef
}

.pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
  color: #818a91;
  cursor: not-allowed;
  background-color: #fff
}

.pager-prev > a, .pager-prev > span {
  float: left
}

.label {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  line-height: 1.5;
  color: #fff;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem
}

.label:empty {
  display: none
}

.btn .label {
  position: relative;
  top: -1px
}

a.label:focus, a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.label-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem
}

.label-default {
  background-color: #818a91
}

.label-default[href]:focus, .label-default[href]:hover {
  background-color: #687077
}

.label-primary {
  background-color: #0275d8
}

.label-primary[href]:focus, .label-primary[href]:hover {
  background-color: #025aa5
}

.label-success {
  background-color: #5cb85c
}

.label-success[href]:focus, .label-success[href]:hover {
  background-color: #449d44
}

.label-info {
  background-color: #5bc0de
}

.label-info[href]:focus, .label-info[href]:hover {
  background-color: #31b0d5
}

.label-warning {
  background-color: #f0ad4e
}

.label-warning[href]:focus, .label-warning[href]:hover {
  background-color: #ec971f
}

.label-danger {
  background-color: #d9534f
}

.label-danger[href]:focus, .label-danger[href]:hover {
  background-color: #c9302c
}

.alert > p, .alert > ul {
  margin-bottom: 0
}

.alert > p + p {
  margin-top: 5px
}

.alert-heading {
  color: inherit
}

.alert-dismissible {
  padding-right: 35px
}

.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit
}

.alert-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #fff
}

.alert-success hr {
  border-top-color: #449d44
}

.alert-success .alert-link {
  color: #e6e6e6
}

.alert-info {
  background-color: #5bc0de;
  border-color: #3db5d8;
  color: #fff
}

.alert-info hr {
  border-top-color: #2aabd2
}

.alert-info .alert-link {
  color: #e6e6e6
}

.alert-danger {
  background-color: #d9534f;
  border-color: #d43f3a;
  color: #fff
}

.alert-danger hr {
  border-top-color: #c9302c
}

.alert-danger .alert-link {
  color: #e6e6e6
}

.list-group {
  padding-left: 0;
  margin-bottom: 0
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd
}

.list-group-item:first-child {
  border-top-right-radius: .25rem;
  border-top-left-radius: .25rem
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.list-group-flush .list-group-item {
  border-width: 1px 0;
  border-radius: 0
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0
}

a.list-group-item, button.list-group-item {
  width: 100%;
  color: #555;
  text-align: inherit
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333
}

a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5
}

.list-group-item.disabled, .list-group-item.disabled:focus, .list-group-item.disabled:hover {
  color: #818a91;
  cursor: not-allowed;
  background-color: #eceeef
}

.nested-menu .list-group-item, .topnav .text-center, button.close {
  cursor: pointer
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading {
  color: inherit
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text {
  color: #818a91
}

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  z-index: 2;
  color: #fff !important;
  background-color: #0275d8;
  border-color: #0275d8;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading > small {
  color: inherit
}

.list-group-item.active .list-group-item-text, .list-group-item.active:focus .list-group-item-text, .list-group-item.active:hover .list-group-item-text {
  color: #a8d6fe
}

.list-group-item-success {
  color: #fff;
  background-color: #5cb85c
}

a.list-group-item-success, button.list-group-item-success {
  color: #fff
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit
}

a.list-group-item-success:focus, a.list-group-item-success:hover, button.list-group-item-success:focus, button.list-group-item-success:hover {
  color: #fff;
  background-color: #4cae4c
}

a.list-group-item-success.active, a.list-group-item-success.active:focus, a.list-group-item-success.active:hover, button.list-group-item-success.active, button.list-group-item-success.active:focus, button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff
}

.list-group-item-info {
  color: #fff;
  background-color: #5bc0de
}

a.list-group-item-info, button.list-group-item-info {
  color: #fff
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit
}

a.list-group-item-info:focus, a.list-group-item-info:hover, button.list-group-item-info:focus, button.list-group-item-info:hover {
  color: #fff;
  background-color: #46b8da
}

a.list-group-item-info.active, a.list-group-item-info.active:focus, a.list-group-item-info.active:hover, button.list-group-item-info.active, button.list-group-item-info.active:focus, button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff
}

.list-group-item-warning {
  color: #fff;
  background-color: #f0ad4e
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #fff
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit
}

a.list-group-item-warning:focus, a.list-group-item-warning:hover, button.list-group-item-warning:focus, button.list-group-item-warning:hover {
  color: #fff;
  background-color: #eea236
}

a.list-group-item-warning.active, a.list-group-item-warning.active:focus, a.list-group-item-warning.active:hover, button.list-group-item-warning.active, button.list-group-item-warning.active:focus, button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff
}

.list-group-item-danger {
  color: #fff;
  background-color: #d9534f
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #fff
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit
}

a.list-group-item-danger:focus, a.list-group-item-danger:hover, button.list-group-item-danger:focus, button.list-group-item-danger:hover {
  color: #fff;
  background-color: #d43f3a
}

a.list-group-item-danger.active, a.list-group-item-danger.active:focus, a.list-group-item-danger.active:hover, button.list-group-item-danger.active, button.list-group-item-danger.active:focus, button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #fff;
  border-color: #fff
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3
}

.close {
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2
}

.tooltip {
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
  text-shadow: none;
  text-transform: none;
  font-size: .875rem;
  text-decoration: none
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5
}

button.close {
  padding: 0;
  background: 0 0;
  border: 0;
  -webkit-appearance: none
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  outline: 0;
}

.modal-footer {
  padding: 15px 24px;
  text-align: right;
  border-top: 1px solid #e5e5e5;

  & > a {
    margin-right: 10px;
  }

  & .btn + .btn {
    margin-bottom: 0;
    margin-left: 15px
  }

  & .btn-group .btn + .btn {
    margin-left: -1px
  }

  & .btn-block + .btn-block {
    margin-left: 0
  }

  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

.modal-header:after {
  display: table;
  content: "";
  clear: both;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-25%)
}

.modal.in .modal-dialog {
  transform: translate(0)
}

.modal-open .modal {
  opacity: 1 !important;
  z-index: 1050 !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  background-clip: padding-box
}


.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;

  &.fade {
    opacity: 0
  }

  &.in {
    opacity: .5
  }
}

.modal-header {
  & .close {
    padding: 4.5px 10px;
  }
}

.modal-title {
  margin: 0;
  line-height: 1.5
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

.m-t-0, .m-y-0 {
  margin-top: 0 !important
}

.m-b-0, .m-y-0 {
  margin-bottom: 0 !important
}

.invisible {
  visibility: hidden !important
}

.text-justify {
  text-align: justify !important
}

.hyfen {
  hyphens: auto;
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.text-muted {
  color: #818a91
}

.text-primary {
  color: #0275d8 !important
}

a.text-primary:focus, a.text-primary:hover {
  color: #025aa5
}

.text-success {
  color: #5cb85c !important
}

a.text-success:focus, a.text-success:hover {
  color: #449d44
}

.text-info {
  color: #5bc0de !important
}

a.text-info:focus, a.text-info:hover {
  color: #31b0d5
}

.text-warning {
  color: #f0ad4e !important
}

a.text-warning:focus, a.text-warning:hover {
  color: #ec971f
}

.text-danger {
  color: #d9534f !important
}

a.text-danger:focus, a.text-danger:hover {
  color: #c9302c
}

.bg-inverse {
  color: #eceeef;
  background-color: #373a3c
}

.bg-danger, .bg-info, .bg-primary, .bg-success, .bg-warning {
  color: #fff !important
}

.bg-faded {
  background-color: #f7f7f9
}

.bg-primary {
  background-color: #0275d8 !important
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #025aa5
}

.bg-success {
  background-color: #5cb85c !important
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #449d44
}

.bg-info {
  background-color: #5bc0de !important
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #31b0d5
}

.bg-warning {
  background-color: #f0ad4e !important
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ec971f
}

.bg-danger {
  background-color: #d9534f !important
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #c9302c
}

.m-r-0, .m-x-0 {
  margin-right: 0 !important
}

.m-a-0 {
  margin: 0 !important
}

.m-l-0, .m-x-0 {
  margin-left: 0 !important
}

.m-r-1, .m-x-1 {
  margin-right: 1rem !important
}

.m-t-1, .m-y-1 {
  margin-top: 1rem !important
}

.m-b-1, .m-y-1 {
  margin-bottom: 1rem !important
}

.m-a-1 {
  margin: 1rem !important
}

.m-l-1, .m-x-1 {
  margin-left: 1rem !important
}

.m-r-2, .m-x-2 {
  margin-right: 1.5rem !important
}

.m-t-2, .m-y-2 {
  margin-top: 1.5rem !important
}

.m-b-2, .m-y-2 {
  margin-bottom: 1.5rem !important
}

.m-a-2 {
  margin: 1.5rem !important
}

.m-l-2, .m-x-2 {
  margin-left: 1.5rem !important
}

.m-r-3, .m-x-3 {
  margin-right: 3rem !important
}

.m-t-3, .m-y-3 {
  margin-top: 3rem !important
}

.m-b-3, .m-y-3 {
  margin-bottom: 3rem !important
}

.m-a-3 {
  margin: 3rem !important
}

.m-l-3, .m-x-3 {
  margin-left: 3rem !important
}

.p-a-0 {
  padding: 0 !important
}

.p-l-0, .p-x-0 {
  padding-left: 0 !important
}

.p-r-1, .p-x-1 {
  padding-right: 1rem !important
}

.p-t-1, .p-y-1 {
  padding-top: 1rem !important
}

.p-b-1, .p-y-1 {
  padding-bottom: 1rem !important
}

.p-a-1 {
  padding: 1rem !important
}

.p-l-1, .p-x-1 {
  padding-left: 1rem !important
}

.p-r-2, .p-x-2 {
  padding-right: 1.5rem !important
}

.p-t-2, .p-y-2 {
  padding-top: 1.5rem !important
}

.p-b-2, .p-y-2 {
  padding-bottom: 1.5rem !important
}

.p-a-2 {
  padding: 1.5rem !important
}

.p-l-2, .p-x-2 {
  padding-left: 1.5rem !important
}

.p-r-3, .p-x-3 {
  padding-right: 3rem !important
}

.p-t-3, .p-y-3 {
  padding-top: 3rem !important
}

.p-b-3, .p-y-3 {
  padding-bottom: 3rem !important
}

.p-a-3 {
  padding: 3rem !important
}

.p-l-3, .p-x-3 {
  padding-left: 3rem !important
}

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.user-avatar {
  border: 3px solid #fff;
  border-radius: 50%
}

#toggle-sidebar {
  position: fixed;
  top: 0;
  left: 5px;
  color: #fff;
  background: #222;
  font-size: 23px;
  z-index: 3;
  display: none
}

.topnav {
  border-radius: 0;
  padding: 6px;
  z-index: 2
}

.topnav .text-center {
  text-align: center;
  padding-left: 0
}

.topnav .top-right-nav .buy-now a {
  color: #999
}

.topnav .top-right-nav .dropdown-menu {
  top: 40px;
  right: -5px;
  left: auto
}

.topnav .top-right-nav .dropdown-menu .message-preview .media .media-body .media-heading {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0
}

.topnav .top-right-nav .dropdown-menu .message-preview .media .media-body p {
  margin: 0
}

.topnav .top-right-nav .dropdown-menu .message-preview .media .media-body p.last {
  font-size: 13px;
  margin-bottom: 0
}

.topnav .top-right-nav .dropdown-menu hr {
  margin-top: 1px;
  margin-bottom: 4px
}

.sidebar {
  position: fixed;
  z-index: 3;
  top: 59px;
  left: 225px;
  width: 16%;
  margin-left: -225px;
  border: none;
  border-radius: 0;
  overflow-y: auto;
  bottom: 0;
  overflow-x: hidden;
  padding-bottom: 40px;
  transition: all .2s ease-in-out
}

.sidebar .list-group a.list-group-item {
  background: #222;
  border: 0;
  border-radius: 0;
  color: #999;
  text-decoration: none
}

.sidebar .list-group a.router-link-active, .sidebar .list-group a:hover {
  background: #151515;
  color: #fff
}

.sidebar .sidebar-dropdown :focus {
  border-radius: 0;
  border: none
}

.sidebar .sidebar-dropdown .panel-title {
  font-size: 1rem;
  height: 50px;
  margin-bottom: 0
}

.sidebar .sidebar-dropdown .panel-title a {
  color: #999;
  text-decoration: none;
  font-weight: 400;
  background: #222
}

.sidebar .sidebar-dropdown .panel-title a span {
  position: relative;
  display: block;
  padding: 1rem 1.5rem .75rem
}

.sidebar .sidebar-dropdown .panel-title a:focus, .sidebar .sidebar-dropdown .panel-title a:hover {
  color: #fff;
  outline: 0;
  outline-offset: -2px
}

.sidebar .sidebar-dropdown .panel-title:hover {
  background: #151515
}

.sidebar .sidebar-dropdown .panel-collapse {
  border-radius: 0;
  border: none
}

.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item {
  border-radius: 0;
  background-color: #222;
  border: 0 solid transparent
}

.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item a {
  color: #999
}

.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item a:hover {
  color: #fff
}

.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item:hover {
  background: #151515
}

.bg-white {
  background: #fff
}

.main-container {
  margin: 50px 0 0 225px;
  padding: 10px;
  -ms-overflow-x: hidden;
  overflow-x: hidden;
  position: relative
}

.chat-panel .chat-dropdown {
  margin-top: -3px
}

.chat-panel .chat {
  height: 350px;
  overflow-y: scroll;
  margin: 0;
  padding: 0
}

.chat-panel .chat .left img {
  margin-right: 15px
}

.chat-panel .chat .right img {
  margin-left: 15px
}

.chat-panel .chat li {
  margin-bottom: 10px;
  margin-right: 15px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #999
}

.chat-panel .card-footer input {
  padding: 3px
}

.sidebarPushRight {
  left: 225px !important;
  z-index: 10
}

.mainContainerPushLeft {
  margin: 55px 255px 0 0
}

.display-sm {
  display: none
}

.hide-sm {
  display: block
}

.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #222;
  text-align: center;
  color: #fff;
  padding: 3em
}

.login-page .col-lg-4 {
  padding: 0
}

.login-page .input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0
}

.login-page .input-underline {
  background: 0 0;
  border: none;
  box-shadow: none;
  border-bottom: 2px solid hsla(0, 0%, 100%, .5);
  color: #fff;
  border-radius: 0
}

.login-page .input-underline:focus {
  border-bottom: 2px solid #fff;
  box-shadow: none
}

.login-page .rounded-btn {
  border-radius: 50px;
  color: hsla(0, 0%, 100%, .8);
  background: #222;
  border: 2px solid hsla(0, 0%, 100%, .8);
  font-size: 18px;
  line-height: 40px;
  padding: 0 25px
}

.login-page .rounded-btn:active, .login-page .rounded-btn:focus, .login-page .rounded-btn:hover, .login-page .rounded-btn:visited {
  color: #fff;
  border: 2px solid #fff;
  outline: 0
}

.login-page h1 {
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 36px
}

.login-page h1 small {
  color: hsla(0, 0%, 100%, .7)
}

.login-page .form-group {
  padding: 8px 0
}

.login-page .form-group input::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, .6) !important
}

.login-page .form-group input:-moz-placeholder, .login-page .form-group input::-moz-placeholder {
  color: hsla(0, 0%, 100%, .6) !important
}

.login-page .form-group input:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, .6) !important
}

.login-page .form-content {
  padding: 40px 0
}

.nested-menu ul.submenu {
  height: 0
}

.nested-menu .expand ul.submenu {
  list-style-type: none;
  height: auto
}

.nested-menu .expand ul.submenu li a {
  color: #fff;
  padding: 10px;
  display: block
}

.loader {
  margin-top: -55px;
  font-size: 11px;
  text-indent: -99999em;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em #222;
  transform: translateZ(0)
}

.loader, .spinner {
  position: fixed !important;
  top: 50%;
  left: 50%;
  margin-left: -55px
}

.spinner {
  margin-top: -10px;
  text-align: center
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both
}

.spinner .bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s
}

.spinner .bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s
}

@keyframes sk-bouncedelay {
  0%, 80%, to {
    transform: scale(0)
  }
  40% {
    transform: scale(1)
  }
}

.chamada1-compra-viajabox, .chamada2-compra-viajabox {
  font-size: 20px;
  font-weight: bold;
  display: block;
  width: 100%;
}

.chamada1-compra-viajabox {
  color: #5199C2;
  padding-bottom: 5px;
}

.chamada2-compra-viajabox {
  color: #202020;
  padding-bottom: 15px;
}

.box-azul-compra-viajabox {
  background: #00446A;
  padding: 60px 0;
  text-align: center;
  margin-top: 130px;
}

.box-azul-compra-viajabox .titulo1-box-azul {
  font-size: 24px;
  font-weight: bold;
  color: #BFE8FF;
  margin-bottom: 10px;
}

.box-azul-compra-viajabox .titulo2-box-azul {
  font-size: 20px;
  font-weight: normal;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.compra-viajabox .ajuda .icones-contato {
  display: block;
}

.compra-viajabox .video .chamada, .compra-viajabox .ajuda .chamada {
  margin: 70px 0 20px 0;
  text-align: left;
}

.compra-viajabox .video iframe {
  border: 1px solid #ccc;
}

.compra-viajabox .ajuda .ajuda-whatsapp, .compra-viajabox .ajuda .ajuda-telefone, .compra-viajabox .ajuda .ajuda-email {
  margin: 0;
}

.compra-viajabox .icones-contato .col-md-12 {
  margin-bottom: 30px;
}

ul.redes-sociais {
  list-style-type: none;

  & li {
    display: inline-block;
    vertical-align: top;
  }
}

a.btn-face:hover .ng-fa-icon, a.btn-face:focus .ng-fa-icon {
  color: #5a80cd !important;
}

a.btn-instagram:hover .ng-fa-icon, a.btn-instagram:focus .ng-fa-icon {
  color: #be236b !important;
}

a.btn-twitter:hover .ng-fa-icon, a.btn-twitter:focus .ng-fa-icon {
  color: #3bb8dd !important;
}

a.btn-youtube:hover .ng-fa-icon, a.btn-youtube:focus .ng-fa-icon {
  color: #ee3238 !important;
}

a.btn-whatsapp:hover, a.btn-whatsapp:focus {
  color: #48985D !important;
}

a.btn-email:hover, a.btn-email:focus {
  color: #4581D2 !important;
}

.btn-whatsapp .ng-fa-icon {
  color: #48985D;
}

.btn-email .ng-fa-icon {
  color: #4581D2;
}

.box-vantagens {
  background: #5199C2;
  color: #fff;
}

.box-texto-vantagens {
  padding: 70px 40px 120px 240px;
  height: 487px;
  float: left;
  width: calc(100% - 450px);
  font-size: 22px;

  & ul {
    list-style-type: square;

    & li:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.icones-contato {
  text-align: center;
  display: flex;

  & img {
    height: 60px;
    display: block;
    margin: 10px auto;
  }

  & a {
    font-weight: 600;
    font-size: 4.2vmin;
    text-decoration: none;

    &:hover {
      color: #228aea !important;
    }
  }
}

.ajuda-whatsapp, .ajuda-telefone, .ajuda-email {
  padding: 20px 0;
  text-align: center;
  margin: 0 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ajuda-whatsapp .ng-fa-icon, .ajuda-telefone .ng-fa-icon, .ajuda-email .ng-fa-icon {
  font-size: 50px;
  margin-right: 20px;
}

.ajuda-whatsapp a, .ajuda-telefone a, .ajuda-email a {
  font-weight: 600;
  font-size: 20px;
}

.ajuda-whatsapp {
  border: 4px solid #8EA414;

  & .ng-fa-icon, & a {
    color: #8EA414 !important;
  }
}

.ajuda-telefone {
  border: 4px solid #FE6F09;

  & a, & .ng-fa-icon {
    color: #FE6F09 !important;
  }
}

.ajuda-email {
  border: 4px solid #094A6F;

  & a, & .ng-fa-icon {
    color: #094A6F !important;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.btn-topo, .colapse, .pointer {
  cursor: pointer;
}

input[capitalize] {
  text-transform: uppercase;
}

input[type="radio"] {
  margin: 5px 4px 0 0;
  vertical-align: top;
}

input[type="checkbox"] {
  margin: 6px 4px 0 0;
  vertical-align: baseline;
}

.grid {
  display: grid;
}

.card-default {
  font-weight: bold;
}

.texto-intro {
  margin-bottom: 20px;
  text-align: justify;
}

#termosCadastro {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.statusProduto {
  border: #c92b1f solid 5px;

  &[ok=true] {
    border: #57a000 solid 5px;
  }
}

app-caixa-usuario {
  display: inline-block;
}

.NgxEditor__Content {
  height: 150px;
  overflow: auto;
}

.desktop {
  display: inline-block;
}

.mobile {
  display: none;
}

.desc {
  display: block;
}

.desc-alt {
  display: none;
}


@media screen and (min-width: 1600px) {
  .ajuda .icones-contato {
    & a {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1210px) {
  .interna {
    &.conteudo {
      padding: 125px 30px 100px 30px;
    }
  }

  .col-lg-3 {
    float: left;
    width: 25%;
  }

  .col-lg-4 {
    float: left;
    width: 33.3%;
  }

  .col-lg-6 {
    float: left;
    width: 50%;
  }

  .col-lg-12 {
    float: left;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .modal-lg {
    width: 90%;
  }
}

@media screen and (max-width: 992px) {
  .interna {
    &.conteudo {
      padding: 125px 10px 80px 10px;
    }

    & h1 {
      font-size: 1.1rem;
    }
  }

  .modal-dialog {
    margin: 10px !important;
    width: auto !important;
    max-width: calc(100vw - 20px);

    & .titulo-modal {
      font-weight: normal;
      font-size: 1.2rem;
    }
  }

  .modal-header .close {
    padding: 0;
    margin: 0;
  }

  table.responsive {
    border: none;

    &, thead, tbody, th, td, tr {
      display: block;
    }

    & thead {
      display: none;
    }

    & tr {
      position: relative;
      border: 1px solid #ccc;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    & td {
      text-align: right;

      &:before {
        content: attr(data-title);
        float: left;
        font-weight: bold;
      }
    }
  }

  .setap {
    padding: 6px;

    & > svg {
      transform: rotate(90deg);
    }
  }

  .desc {
    display: none;
  }

  .desc-alt {
    display: block;
  }
}

@media screen and (max-width: 900px) {

  .interna.conteudo {
    padding: 100px 15px 80px 15px;
  }

  .panel-title > span:first-child {
    border-bottom: 2px dotted #ccc;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    padding-bottom: 5px;
  }

  .opcoes-accordion:not(.disable-split) {
    border-top: 2px dotted #ccc;
    margin-top: 10px;
    width: 100% !important;
    padding-top: 5px;
    display: block;
    position: relative;
    float: right;
    right: 0;
  }

  #accordion {
    .dados-cabecalho-accordion {
      width: 100%;
    }

    .panel-title {
      & > span:first-child:after {
        content: "" !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  table {
    .tohide {
      display: none;
    }

    .sscreen {
      display: table-cell;
    }
  }

  .opcoes {
    .ng-fa-icon {
      padding: 0;
    }
  }
}

@media screen and (max-width: 680px) {
  .tooltip {
    display: none !important;
  }

  .interna.conteudo {
    padding: 120px 15px 50px 15px;
    border-top: none;
  }

  .titulo-pagina {
    & h1 {
      font-size: 20px !important;
      padding: 20px 20px 20px 0 !important;
    }
  }

  .dropdown-menu {
    position: fixed;
    top: 45px;
    width: calc(100% - 10px);
    min-width: 0 !important;
    left: 5px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: inline-block;
  }

  .interna.conteudo h1 {
    margin-bottom: 8px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .col-estoque-top, .col-envios {
    width: 100% !important;
  }

  table {
    .xscreen {
      display: table-cell;
    }

    .xinline {
      display: inline-block;
    }

    &.responsive {
      & td {
        padding: 10px;
        text-align: left;

        &:before {
          float: none;
          display: block;
          text-align: left;
          padding-bottom: 4px;
        }
      }
    }
  }

  .opcoes-accordion {
    & .ng-fa-icon {
      padding-top: 5px;
      padding-left: 10px;
      font-size: 1.5em;
    }
  }

  .opcoes-tabela {
    & .ng-fa-icon {
      padding-left: 10px;
      font-size: 2em;
    }
  }
}

@media screen and (min-width: 550px) {
  .ajuda .icones-contato {
    & a {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 440px) {
  .titulo-pagina {
    background: linear-gradient(-35deg, #00446A 30%, #FE6A00 30.5%);
  }

  .ajuda-whatsapp, .ajuda-telefone, .ajuda-email {
    display: block !important;
    padding: 20px 10px;

    & > .ng-fa-icon {
      width: 100%;
      padding-bottom: 10px;
    }
  }

  .cropArea {
    width: 100%;
    height: auto;
  }
}

// Correção das imagens no Chrome

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
  img {
    //noinspection CssInvalidPropertyValue
    image-rendering: -webkit-optimize-contrast !important;
  }
}

/* Unset for Safari 11+ */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    img {
      image-rendering: unset !important;
    }
  }
}
