.carousel, .carousel-inner {
  background-color: #F8F8F8;
}

.carousel-control {
  width: 60px;

  &.left, &.right {
    background-image: none !important;
  }
}

.carousel-indicators {
  & > button {
    width: 21px !important;
    height: 21px !important;
    background: #D1D1D1;
    border: none;
    margin: 8px !important;
    border-radius: 100px;

    &.active {
      background: #FF5C03;
    }
  }
}

.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
  width: 50px;
  height: 50px;
  margin-top: -25px;
  font-size: 50px;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
    width: 70px;
    height: 70px;
    margin-top: -35px;
    font-size: 70px;
  }
}
